import React from "react"

import Layout        from "../components/layout"
import SEO           from "../components/seo"
import Hero          from "../components/hero"
import MainContainer from "../components/main_container"
import Services      from "../components/services"
import AboutMe       from "../components/about_me"
import HireMe        from "../components/hire_me"
import MySkills      from "../components/my_skills"
import Portfolio     from "../components/portfolio"
import Facts         from "../components/facts"
import Testimonials  from "../components/testimonials"
import Partners      from "../components/partners"
import Footer        from "../components/footer"

const siteContent = {
  hero:  {
    title: "Hi I'm Maxime Nguyen",
    jobs:  [
      "Frontend developer",
      "Remote developer",
    ],
  },
  about: {
    socialNetworks: [
      {
        name: "facebook",
        link: "https://www.facebook.com/zed.dubled",
        icon: "fab fa-facebook",
      },
      {
        name: "twitter",
        link: "https://twitter.com/maximengn_",
        icon: "fab fa-twitter",
      },
      {
        name: "dribbble",
        link: "https://dribbble.com/zedissime",
        icon: "fab fa-dribbble",
      },
      {
        name: "github",
        link: "https://github.com/zedissime",
        icon: "fab fa-github",
      },
      {
        name: "instagram",
        link: "https://www.instagram.com/zeddubled/",
        icon: "fab fa-instagram",
      },
    ],
  },
}
const IndexPage = () => (
  <Layout>
    <SEO title="Maxime Nguyen" keywords={ [`frontend developer`, `maximenguyen`, `react`] } />
    <div className="ts-page-wrapper" id="page-top">
      <Hero hero={ siteContent.hero } socialNetworks={ siteContent.about.socialNetworks } />
      <MainContainer>
        <Services />
        <AboutMe />
        <HireMe />
        <MySkills />
        <Portfolio />
        <Facts />
        <Testimonials />
        <Partners />
      </MainContainer>
      <Footer />
    </div>
  </Layout>
)

export default IndexPage
