import React     from 'react'
import ImgWork01 from '../assets/images/img-work-01.png'
import ImgWork02 from '../assets/images/img-work-02.png'
import ImgWork03 from '../assets/images/img-work-03.png'
import ImgWork04 from '../assets/images/img-work-04.png'
import ImgWork05 from '../assets/images/img-work-05.png'
import ImgWork06 from '../assets/images/img-work-06.png'
import ImgWork07 from '../assets/images/img-work-07.png'
import ImgWork08 from '../assets/images/img-work-08.png'
import ImgWork10 from '../assets/images/img-work-10.png'
import ImgWork11 from '../assets/images/img-work-11.png'
import ImgWork09 from '../assets/images/img-work-09.png'

const Portfolio = () => {
  return <section className="ts-block pb-5" id="portfolio">
    <div className="container">
      <div className="ts-title">
        <h2>Portfolio</h2>
      </div>
      <div className="card-columns ts-gallery ts-column-count-4">
        <a href="assets/img/img-work-01.png" className="card ts-gallery__item popup-image" data-animate="ts-fadeInUp">
          <div className="ts-gallery__item-description">
            <h6 className="ts-opacity__50">Branding</h6>
            <h4>Pehaz</h4>
          </div>
          <img src={ ImgWork01 } className="card-img" alt="" />
        </a>
        <a href="assets/img/img-work-02.png" className="card ts-gallery__item popup-image" data-animate="ts-fadeInUp">
          <div className="ts-gallery__image">
            <div className="ts-gallery__item-description">
              <h6 className="ts-opacity__50">Webdesign</h6>
              <h4>Carilo</h4>
            </div>
            <img src={ ImgWork02 } className="card-img" alt="" />
          </div>
        </a>
        <a href="assets/img/img-work-03.png" className="card ts-gallery__item popup-image" data-animate="ts-fadeInUp">
          <div className="ts-gallery__image">
            <img src={ ImgWork03 } className="card-img" alt="" />
            <div className="ts-gallery__item-description">
              <h6 className="ts-opacity__50">Typography</h6>
              <h4>Kali</h4>
            </div>
          </div>
        </a>
        <a href="assets/img/img-work-04.png" className="card ts-gallery__item popup-image" data-animate="ts-fadeInUp">
          <div className="ts-gallery__image">
            <img src={ ImgWork04 } className="card-img" alt="" />
            <div className="ts-gallery__item-description">
              <h6 className="ts-opacity__50">Identity</h6>
              <h4>Purity</h4>
            </div>
          </div>
        </a>
        <a href="assets/img/img-work-05.png" className="card ts-gallery__item popup-image" data-animate="ts-fadeInUp">
          <div className="ts-gallery__image">
            <img src={ ImgWork05 } className="card-img" alt="" />
            <div className="ts-gallery__item-description">
              <h6 className="ts-opacity__50">Coding</h6>
              <h4>SawMill</h4>
            </div>
          </div>
        </a>
        <a href="assets/img/img-work-06.png" className="card ts-gallery__item popup-image" data-animate="ts-fadeInUp">
          <div className="ts-gallery__image">
            <img src={ ImgWork06 } className="card-img" alt="" />
            <div className="ts-gallery__item-description">
              <h6 className="ts-opacity__50">Webdesign</h6>
              <h4>Browar</h4>
            </div>
          </div>
        </a>
        <a href="assets/img/img-work-07.png" className="card ts-gallery__item popup-image" data-animate="ts-fadeInUp">
          <div className="ts-gallery__image">
            <img src={ ImgWork07 } className="card-img" alt="" />
            <div className="ts-gallery__item-description">
              <h6 className="ts-opacity__50">Experiment</h6>
              <h4>Wood Tables</h4>
            </div>
          </div>
        </a>
        <a href="assets/img/img-work-08.png" className="card ts-gallery__item popup-image" data-animate="ts-fadeInUp">
          <div className="ts-gallery__image">
            <img src={ ImgWork08 } className="card-img" alt="" />
            <div className="ts-gallery__item-description">
              <h6 className="ts-opacity__50">Product Design</h6>
              <h4>Air Purifier</h4>
            </div>
          </div>
        </a>
        <a href="assets/img/img-work-10.png" className="card ts-gallery__item popup-image" data-animate="ts-fadeInUp">
          <div className="ts-gallery__image">
            <img src={ ImgWork10 } className="card-img" alt="" />
            <div className="ts-gallery__item-description">
              <h6 className="ts-opacity__50">App Developing</h6>
              <h4>Boombox</h4>
            </div>
          </div>
        </a>
        <a href="assets/img/img-work-11.png" className="card ts-gallery__item popup-image" data-animate="ts-fadeInUp">
          <div className="ts-gallery__image">
            <img src={ ImgWork11 } className="card-img" alt="" />
            <div className="ts-gallery__item-description">
              <h6 className="ts-opacity__50">3D Art</h6>
              <h4>The Deer</h4>
            </div>
          </div>
        </a>
        <a href="assets/img/img-work-09.png" className="card ts-gallery__item popup-image" data-animate="ts-fadeInUp">
          <div className="ts-gallery__image">
            <img src={ ImgWork09 } className="card-img" alt="" />
            <div className="ts-gallery__item-description">
              <h6 className="ts-opacity__50">Rebranding</h6>
              <h4>Dafont</h4>
            </div>
          </div>
        </a>
      </div>
    </div>
  </section>
}

export default Portfolio
