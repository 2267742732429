import React  from 'react'
import workBg from '../assets/images/bg-desk.jpg'

const HireMe = () => {
  return <section className="ts-block">
    <div className="container">
      <div className="text-center px-5 pt-5 position-relative">
        <h3 className="my-3">
          I'm currently working at <a href="http://openbroker.be" target="_blank">Openbroker</a>
        </h3>
        <a href="#contact" className="btn btn-primary mr-2 ts-push-down__50 ts-has-talk-arrow ts-scroll">Reach me!</a>
        <div className="ts-background ts-opacity__20" data-bg-image={ workBg } />
      </div>
    </div>
  </section>
}

export default HireMe
