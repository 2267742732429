import React from "react"
import MNG   from "../assets/images/mng.png"

const AboutMe = () => {
  return <section id="about-me" className="ts-block pb-4">
    <div className="container">
      <div className="ts-title text-center">
        <h2>About Me</h2>
      </div>
      <div className="row ts-align__vertical">
        <div className="col-md-6">
          <img src={ MNG } alt="" className="mw-100 mb-5 about-picture" />
        </div>
        <div className="col-md-6">
          <h4 className="ts-bubble-border">Hi There</h4>
          <p className="about-description">
            I did my master's degree at <a href="http://www.iad-arts.be/" target="_blank">Institut des arts de
            Diffusion</a>.
            After that I did work as front-end developer at <a href="https://belighted.com"
                                                               target="_blank">Belighted</a> and now I'm a
            senior front-end developer at <a href="https://openbroker.be" target="_blank">Openbroker</a>.
          </p>
          <dl className="ts-column-count-2">
            <dt>Name:</dt>
            <dd>Maxime Nguyen</dd>
            <dt>Phone:</dt>
            <dd><a href="tel:+32 474 38 88 91">+32 474 38 88 91</a></dd>
            <dt>Email:</dt>
            <dd><a href="mailto:contact@maximenguyen.com">contact@maximenguyen.com</a></dd>
            <dt>Twitter:</dt>
            <dd><a href="https://twitter.com/maximengn_" target="_blank">@maximengn_</a></dd>
          </dl>
          <hr className="ts-hr-light mb-5" />
          <a href="#contact" className="ts-btn-effect mr-2 ts-scroll">
            <span className="ts-visible btn btn-primary ts-btn-arrow">Contact Me</span>
            <span className="ts-hidden btn btn-primary ts-btn-arrow" data-bg-color="#d44729">Contact Me</span>
          </a>
          <a href="#contact" className="ts-btn-effect mr-2 ts-scroll">
            <span className="ts-visible btn btn-outline-light">
                <i className="fa fa-download small mr-2" />
                Download CV
            </span>
            <span className="ts-hidden btn btn-light bg-white">
              <i className="fa fa-download small mr-2" />
              Download CV
            </span>
          </a>
        </div>
      </div>
    </div>
  </section>
}

export default AboutMe
