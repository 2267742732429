import React     from 'react'
import PropTypes from 'prop-types'
import BgHero    from '../assets/images/bg-hero.jpg'

const Hero = ({ socialNetworks, hero }) => {
  return <div id="ts-hero" className="ts-animate-hero-items">
    <div className="container position-relative h-100 ts-align__vertical">
      <div className="row w-100">
        <div className="col-md-8">
          <figure className="ts-social-icons mb-4">
            { socialNetworks.map((socialNetwork) => (
              <a href={ socialNetwork.link } target="_blank" className="mr-3" key={ socialNetwork.name }>
                <i className={ socialNetwork.icon } />
              </a>
            )) }
          </figure>
          <h1>{ hero.title }</h1>
          <h1 className="ts-bubble-border">
            <span className="ts-title-rotate">
              { hero.jobs.map((job, index) => (
                <span className={ index === 0 ? 'active' : '' } key={ job }>{ job }</span>
              )) }
            </span>
          </h1>

        </div>
      </div>
      <a href="#my-services" className="ts-btn-effect position-absolute ts-bottom__0 ts-left__0 ts-scroll ml-3 mb-3">
        <span className="ts-visible ts-circle__sm rounded-0 ts-bg-primary">
          <i className="fa fa-arrow-down text-white" />
        </span>
        <span className="ts-hidden ts-circle__sm rounded-0">
          <i className="fa fa-arrow-down text-white" />
        </span>
      </a>
    </div>
    <div className="ts-background">
      <div className="ts-background-image" data-bg-image={ BgHero } />
    </div>
  </div>
}

Hero.propTypes = {
  socialNetworks: PropTypes.array.isRequired,
  hero:           PropTypes.object.isRequired,
}

export default Hero
