import React from 'react'

const MySkills = () => {
  return <section id="my-skills" className="ts-block pb-5">
    <div className="container">
      <div className="ts-title text-center">
        <h2>My Skills</h2>
      </div>
      <h4>Keep learning every day</h4>
      <div className="row">
        <div className="col-md-6">
          <p>
            In id nulla magna. Nullam posuere fermentum mattis. Nunc id dui at sapien faucibus fermentum
            ut vel diam. Nullam tempus, nunc id efficitur sagittis, urna est ultricies eros, ac porta
            sem turpis quis leo. Nulla in feugiat elit.
          </p>
          <p>
            Phasellus accumsan scelerisque dolor, quis mattis justo bibendum non. Nulla sollicitudin
            turpis in enim elementum varius. Vestibulum ante ipsum primis in faucibus orci luctus
            et ultrices posuere cubilia Curae
          </p>
          <a href="#contact" className="btn btn-outline-light mb-5 ts-scroll">Contact Me</a>
        </div>
        <div className="col-md-6">
          <div className="progress" data-progress-width="90%">
            <h5 className="ts-progress-title">Javascript</h5>
            <figure className="ts-progress-value" />
            <div className="progress-bar"
                 role="progressbar"
                 aria-valuenow="0"
                 aria-valuemin="0"
                 aria-valuemax="100" />
          </div>
          <div className="progress" data-progress-width="80%">
            <h5 className="ts-progress-title">HTML/CSS</h5>
            <figure className="ts-progress-value" />
            <div className="progress-bar"
                 role="progressbar"
                 aria-valuenow="0"
                 aria-valuemin="0"
                 aria-valuemax="100" />
          </div>
          <div className="progress" data-progress-width="80%">
            <h5 className="ts-progress-title">Sketch</h5>
            <figure className="ts-progress-value" />
            <div className="progress-bar"
                 role="progressbar"
                 aria-valuenow="0"
                 aria-valuemin="0"
                 aria-valuemax="100" />
          </div>
          <div className="progress" data-progress-width="40%">
            <h5 className="ts-progress-title">React-Native / Ionic</h5>
            <figure className="ts-progress-value" />
            <div className="progress-bar"
                 role="progressbar"
                 aria-valuenow="0"
                 aria-valuemin="0"
                 aria-valuemax="100" />
          </div>
        </div>
      </div>
    </div>
  </section>
}

export default MySkills
