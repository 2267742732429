import React            from 'react'
import { BgManSitting } from '../assets/images/bg-man-sitting.jpg'

const Facts = () => {
  return <section className="ts-block" data-bg-image={ BgManSitting }>
    <div className="container ts-promo-numbers">
      <div className="row">
        <div className="col-sm-6 col-md-3">
          <div className="ts-promo-number text-center">
            <figure className="odometer" data-odometer-final="43">0</figure>
            <h5>Clients</h5>
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <div className="ts-promo-number text-center">
            <figure className="odometer" data-odometer-final="68">0</figure>
            <h5>Projects</h5>
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <div className="ts-promo-number text-center">
            <figure className="odometer" data-odometer-final="17">0</figure>
            <h5>Awards</h5>
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <div className="ts-promo-number text-center">
            <figure className="odometer" data-odometer-final="12">0</figure>
            <h5>Years Experience</h5>
          </div>
        </div>
      </div>
    </div>
  </section>
}

export default Facts
