import React     from 'react'
import code      from '../assets/images/services/code.svg'
import ux        from '../assets/images/services/ux.svg'
import wireframe from '../assets/images/services/wireframe.svg'
import mockup    from '../assets/images/services/mockup.svg'
import design    from '../assets/images/services/design.svg'

const Services = () => {
  return <section id="my-services" className="ts-block ts-xs-text-center pb-0">
    <div className="container">
      <div className="ts-title text-center">
        <h2>My Services</h2>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-4 col-xl-4">
          <div className="ts-item" data-animate="ts-fadeInUp">
            <div className="ts-item-content">
              <div className="ts-item-header">
                <figure className="icon">
                  <img src={ code } alt="" />
                </figure>
              </div>
              <div className="ts-item-body">
                <h4>Code</h4>
                <p className="mb-0">
                  Based on 5 years of experience in web development I could improve myself in javascript development.
                </p>
              </div>
              <div className="ts-item-footer">
                <a href="#" data-toggle="modal" data-target="#modal" className="ts-link-arrow-effect">
                  <span>Read More</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-xl-4">
          <div className="ts-item" data-animate="ts-fadeInUp" data-delay=".1s">
            <div className="ts-item-content">
              <div className="ts-item-header">
                <figure className="icon">
                  <img src={ design } alt="" />
                </figure>
              </div>
              <div className="ts-item-body">
                <h4>Design</h4>
                <p className="mb-0">
                  With a master degree in an artistic high school, I have some facilities in designing website.
                </p>
              </div>
              <div className="ts-item-footer">
                <a href="#" data-toggle="modal" data-target="#modal" className="ts-link-arrow-effect">
                  <span>Read More</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-xl-4">
          <div className="ts-item" data-animate="ts-fadeInUp" data-delay=".2s">
            <div className="ts-item-content">
              <div className="ts-item-header">
                <figure className="icon">
                  <img src={ wireframe } alt="" />
                </figure>
              </div>
              <div className="ts-item-body">
                <h4>Wireframes</h4>
                <p className="mb-0">
                  A good application always comes from brainstorming and many iterations based on wireframes.
                </p>
              </div>
              <div className="ts-item-footer">
                <a href="#" data-toggle="modal" data-target="#modal" className="ts-link-arrow-effect">
                  <span>Read More</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-xl-4">
          <div className="ts-item" data-animate="ts-fadeInUp" data-delay=".3s">
            <div className="ts-item-content">
              <div className="ts-item-header">
                <figure className="icon">
                  <img src={ ux } alt="" />
                </figure>
              </div>
              <div className="ts-item-body">
                <h4>UX first</h4>
                <p className="mb-0">
                  Always think as the end-user will help to develop the right interface.
                </p>
              </div>
              <div className="ts-item-footer">
                <a href="#" data-toggle="modal" data-target="#modal" className="ts-link-arrow-effect">
                  <span>Read More</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-xl-4">
          <div className="ts-item" data-animate="ts-fadeInUp" data-delay=".4s">
            <div className="ts-item-content">
              <div className="ts-item-header">
                <figure className="icon">
                  <img src={ mockup } alt="" />
                </figure>
              </div>
              <div className="ts-item-body">
                <h4>Mockup</h4>
                <p className="mb-0">
                  I also develop dynamic prototypes based on wireframes which help defining the right interface and user experience.
                </p>
              </div>
              <div className="ts-item-footer">
                <a href="#" data-toggle="modal" data-target="#modal" className="ts-link-arrow-effect">
                  <span>Read More</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
}

export default Services
