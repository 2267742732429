import React  from 'react'
import Logo01 from '../assets/images/logo-01-w.png'
import Logo02 from '../assets/images/logo-02-w.png'
import Logo03 from '../assets/images/logo-03-w.png'
import Logo04 from '../assets/images/logo-04-w.png'
import Logo05 from '../assets/images/logo-05-w.png'

const Partners = () => {
  return <section id="partners" className="ts-block py-4">
    <div className="container">
      <div className="d-block d-md-flex justify-content-between align-items-center text-center ts-partners py-3">
        <a href="#">
          <img src={ Logo01 } alt="" />
        </a>
        <a href="#">
          <img src={ Logo02 } alt="" />
        </a>
        <a href="#">
          <img src={ Logo03 } alt="" />
        </a>
        <a href="#">
          <img src={ Logo04 } alt="" />
        </a>
        <a href="#">
          <img src={ Logo05 } alt="" />
        </a>
      </div>
    </div>
  </section>
}

export default Partners
